import React from "react"
import { AuthContext } from "../state/provider"
import { navigate } from "gatsby"

export default Component => props => (
  <AuthContext.Consumer>
    {stateStore => {
      if (typeof window !== "undefined") {
        if (!stateStore) {
          navigate("/")
        }
        const { checkLogin } = stateStore
        if (!checkLogin()) {
          navigate("/login")
        }
      }
      return <Component {...props} state={{ ...stateStore }}/>
    }}
  </AuthContext.Consumer>
)
