import React from "react"
import { Router } from "@reach/router"

import SEO from "../components/seo"
import CandidateProfile from "../components/partials/profile"
import ContainerWrapper from "../components/container"

import withAuth from "../util/withAuth"

const Jobs = () => (
  <>
    <SEO title="My Career Profile" keywords={[`mazrui`, `careers`]} />
    <ContainerWrapper classNames="main candidate-profile" fluid>
      <Router>
        <CandidateProfile path="/account/:action" />
      </Router>
    </ContainerWrapper>
  </>
)

export default withAuth(Jobs)
